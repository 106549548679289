import { useState, useCallback, useMemo } from "react";

import { Emptyable } from "@simplyk/common";
import { useRouter } from "next/router";

import { Button } from "../../components/design-system/Button";
import { AmplitudeEvents } from "../../constants/amplitude";
import { RecaptchaAction } from "../../enums/recaptcha";
import { SignInFrontendInput } from "../../gql/gql-types";
import { SignInMutation, useSignInMutation } from "../../gql/queries/generated/authQuery";
import { setAuthAndRedirectToApp } from "../../helpers/auth";
import { captureSentryError } from "../../helpers/sentry";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useCanny } from "../../hooks/useCanny";
import { useGtm } from "../../hooks/useGtm";
import { useRecaptchaRetry } from "../../hooks/useRecaptchaRetry";
import { useTranslate } from "../../hooks/useTranslate";
import { useView } from "../../hooks/useView";
import { OrganizationRoutes } from "../../routes/routes";

import { ChevronLeft } from "@/icons/outlined";

declare global {
  interface Window {
    GetTelemetryID: (args: { publicToken: string; submitURL: string }) => string;
  }
}

export enum SignInStep {
  CheckEmail = "checkEmail",
  EnterPassword = "enterPassword",
  ResetPassword = "resetPassword",
  DonorLogin = "DonorLogin",
  TwoFactorAuth = "TwoFactorAuth",
  EnterSMSCode = "EnterSMSCode",
}

const getQueryParams = (url: string) => {
  const searchParams = url.split("?")[1];
  const params = new URLSearchParams(searchParams);
  const result: Record<string, string> = {};

  for (const [key, value] of params.entries()) {
    result[key] = value;
  }
  return result;
};

export const useSignIn = () => {
  const { logAmplitudeEvent } = useAmplitude();
  const { execute } = useRecaptchaRetry();
  const router = useRouter();
  const { redirectToCanny } = useCanny();
  const { pushGtmLogin } = useGtm();
  const { setView } = useView();
  const { canny, redirectionUrl } = router.query as {
    canny: "true" | null;
    redirectionUrl: string | null;
  };

  const decodedRedirectionUrl = redirectionUrl ? decodeURIComponent(redirectionUrl) : null;

  const targetOrganizationId = decodedRedirectionUrl
    ? getQueryParams(decodedRedirectionUrl).targetOrganizationId
    : null;

  const { t } = useTranslate();
  const [execSignIn] = useSignInMutation();

  const [signInStep, setSignInStep] = useState<SignInStep>(SignInStep.CheckEmail);

  const { email: defaultEmail } = router.query as {
    redirection: string | null;
    email: string | undefined;
  };
  const [email, setEmail] = useState<string>(defaultEmail || "");
  const [password, setPassword] = useState<string>("");
  const [stytchVerdict, setStytchVerdict] = useState<Emptyable<string>>(null);
  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState<string>("***-***-****");

  const handleChangeEmail = (email: string) => {
    setEmail(email);
  };

  const redirectToResetPassword = useCallback(() => {
    logAmplitudeEvent(AmplitudeEvents.UserForgotPasswordClicked);
    setSignInStep(SignInStep.ResetPassword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToTwoFactorAuth = useCallback(() => {
    setSignInStep(SignInStep.TwoFactorAuth);
  }, []);

  const goToSMSCode = useCallback(() => {
    setSignInStep(SignInStep.EnterSMSCode);
  }, []);

  const redirectToLogin = useCallback(() => {
    setSignInStep(SignInStep.CheckEmail);
  }, []);

  const displayBackButton = signInStep === SignInStep.EnterPassword || signInStep === SignInStep.TwoFactorAuth;

  const loginBack = useCallback(() => {
    logAmplitudeEvent(AmplitudeEvents.LoginClickBack, { initialStep: signInStep });
    if (signInStep === SignInStep.EnterPassword) {
      setSignInStep(SignInStep.CheckEmail);
    }
    if (signInStep === SignInStep.TwoFactorAuth) {
      setSignInStep(SignInStep.EnterPassword);
    }
  }, [logAmplitudeEvent, signInStep]);

  const loginBackButton = useMemo(() => {
    return (
      <Button vibe="neutral" startIcon={<ChevronLeft />} onClick={loginBack}>
        {t("dashboard", "common.back")}
      </Button>
    );
  }, [loginBack, t]);

  const getTelemetryID = async (): Promise<string | null> => {
    const STYTCH_PUBLIC_TOKEN = process.env.NEXT_PUBLIC_STYTCH_PUBLIC_TOKEN;

    if (!STYTCH_PUBLIC_TOKEN) {
      captureSentryError({
        message: "STYTCH_PUBLIC_TOKEN is not defined",
      });
      return null;
    }

    if (typeof window === "undefined" || typeof window.GetTelemetryID !== "function") {
      captureSentryError({
        message: "GetTelemetryID is not defined",
        params: {
          getTelemetryID: window.GetTelemetryID,
        },
      });
      return null;
    }

    try {
      const telemetryId: string = await Promise.race([
        window.GetTelemetryID({
          publicToken: STYTCH_PUBLIC_TOKEN,
          // It's required to use the auth.zeffy.com domain to make the Device Fingerprintering work.
          submitURL: `https://auth.zeffy.com/submit`,
        }),
        new Promise<string>((_, reject) => setTimeout(() => reject(new Error("GetTelemetryID timed out")), 3000)),
      ]);

      return telemetryId;
    } catch (error) {
      captureSentryError({
        message: "GetTelemetryID failed",
        params: {
          error: JSON.stringify(error),
        },
      });
      return null;
    }
  };

  const signin = useCallback(
    async (signInInput: SignInFrontendInput): Promise<SignInMutation | null | undefined> => {
      const STYTCH_PUBLIC_TOKEN = process.env.NEXT_PUBLIC_STYTCH_PUBLIC_TOKEN;

      const telemetryId = STYTCH_PUBLIC_TOKEN ? await getTelemetryID() : null;

      const action = RecaptchaAction.SignIn;
      const email = signInInput.email;
      signInInput.targetOrganizationId = targetOrganizationId;
      const { data: signInResponse } = await execute(action, email, execSignIn, {
        variables: {
          signInInput: { ...signInInput, telemetryId },
        },
      });
      if (signInResponse?.signIn?.object) {
        setMaskedPhoneNumber(signInResponse.signIn.object.maskedPhoneNumber || "***-***-****");
        if (signInResponse?.signIn?.object?.shouldAuthenticateWithTwoFactor) {
          goToTwoFactorAuth();
        }
        if (signInResponse?.signIn?.object?.shouldAuthenticateBySms) {
          setStytchVerdict(signInResponse?.signIn?.object?.stytchVerdict);
          goToSMSCode();
        }
        if (signInResponse?.signIn?.object?.role) {
          logAmplitudeEvent(AmplitudeEvents.SignInCompleted, {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            "2fa_used": false,
            ...(signInInput.googleToken && { sso_type: "Google" }),
          });
          if (canny === "true") {
            redirectToCanny();
            return signInResponse;
          }

          const redirection = signInResponse?.signIn?.object?.redirectUrl || decodedRedirectionUrl;
          pushGtmLogin({ email: signInResponse.signIn.object.email || "" });

          if (signInInput.smsCode) {
            logAmplitudeEvent(AmplitudeEvents.TwoFASuccessful, { moment: "login" });
          }

          if (signInResponse?.signIn?.object?.shouldRedirectToMFAConfig) {
            await router.push(
              `${OrganizationRoutes.MFALogin}?role=${signInResponse.signIn.object.role}&redirectionUrl=${redirection || ""}`
            );
          } else {
            setAuthAndRedirectToApp({
              role: signInResponse.signIn.object.role,
              setView,
              redirectionUrl: redirection,
              reload: true,
            });
          }

          return signInResponse;
        }
      }
      return signInResponse;
    },
    [
      targetOrganizationId,
      execute,
      execSignIn,
      goToTwoFactorAuth,
      goToSMSCode,
      logAmplitudeEvent,
      canny,
      decodedRedirectionUrl,
      pushGtmLogin,
      redirectToCanny,
      router,
      setView,
    ]
  );

  return {
    signInStep,
    setSignInStep,
    email,
    handleChangeEmail,
    redirectToResetPassword,
    redirectToLogin,
    password,
    setPassword,
    displayBackButton,
    loginBack,
    loginBackButton,
    signin,
    stytchVerdict,
    maskedPhoneNumber,
  };
};
